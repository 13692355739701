import { useContext } from 'react'
import Chart from 'react-apexcharts'

import { ThemeContext } from 'styled-components'

const ScrollableVisitorChart = ({ data }) => {
  const theme = useContext(ThemeContext);

  const options = {
    chart: {
      id: 'hourlyvisitor',
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false
      },
      axisBorder: {
        color: theme.colors.text
      },
      axisTicks: {
        color: theme.colors.text
      },
      labels: {
        style: {
          colors: theme.colors.text
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.text
        }
      }
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy, HH:mm'
      }
    }
  }

  const series = [
    {
      name: 'visitor',
      data
    }
  ]

  return <Chart
    series={series}
    options={options}
    height="100%"
    type="bar"
  />
}

export default ScrollableVisitorChart
