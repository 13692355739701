import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { serverBaseUrl } from '../constants';

export const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(serverBaseUrl, {
          path: '/v1/socket.io',
        })
      );
    }
  }, [socket]);

  useEffect(() => {
    return () => socket && socket.disconnect();
  }, [socket]);

  return socket;
};
