import styled from 'styled-components';
import { Button, Table as RbTable } from 'react-bootstrap';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

export const Table = styled(RbTable)`
  background-color: ${p => p.theme.colors.surface};
`;

export const ActionButtonLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.colors.primary};
  border: none;
  padding: 0.7em;

  &:hover {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)};
  }

  &:disabled {
    background-color: ${p => lighten(0.07, p.theme.colors.primary)};
  }

  &:active,
  &:focus {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)} !important;
  }
`;
