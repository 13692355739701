import { useState } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ToggleButton from '../ToggleButton';
import DeviceLog from '../DeviceLog';

import {
  HeaderContainer,
  Title
} from './logs.components'

const Logs = ({ data, socket, clientId }) => {
  const [type, setType] = useState("heartbeat")

  const handleOnChange = (_, newType) => {
    if (newType !== null) {
      setType(newType)
    }
  }

  return (
    <>
      <HeaderContainer>
        <Title>Logs</Title>
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={handleOnChange}
        >
          <ToggleButton value="heartbeat">
            Heartbeat
          </ToggleButton>
          <ToggleButton value="connection">
            Connection
          </ToggleButton>
        </ToggleButtonGroup>
      </HeaderContainer>
      <DeviceLog data={data} type={type} socket={socket} clientId={clientId} />
    </>
  )
}

export default Logs
