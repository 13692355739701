import styled from 'styled-components';
import { lighten } from 'polished';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  margin-left: ${p => p.theme.sidebar.width}px;
  padding: 1em 2em;
  background-color: ${p => p.theme.colors.background};
  min-height: 100vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  color: ${p => p.theme.colors.text};
`;

export const PingButton = styled(Button)`
  background-color: ${p => p.theme.colors.primary};
  border: none;

  &:hover {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)};
  }

  &:active,
  &:focus {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)} !important;
  }
`;
