
import {
  Container,
  Icon,
  TextContainer,
  Title,
  Value,
  ValueContainer,
  Growth
} from './overviewCard.components'

const OverviewCard = ({ icon, title, value, color, growth }) => {
  return (
    <Container>
      <Icon color={color}>
        {icon}
      </Icon>
      <TextContainer>
        <Title>{title}</Title>
        <ValueContainer>
          <Value>{value}</Value>
          <Growth isPositive={growth >= 0}>
            {growth >= 0 ? '+' : '-'} {growth >= 0 ? growth.toFixed(1) : growth.toFixed(1).substring(1)}%
          </Growth>
        </ValueContainer>
      </TextContainer>
    </Container>
  )
}

export default OverviewCard
