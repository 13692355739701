import { useState, useEffect, memo } from 'react';
import Fade from '@material-ui/core/Fade';
import moment from 'moment';

import axios from '../../axios'
import { Row, Time, Action, Container } from './recentActivities.components'

const RecentActivities = ({ socket }) => {
  const [activities, setActivities] = useState([]);

  const fetchActivity = async () => {
    try {
      const { data } = await axios.get('/v1/iot/get/activity', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      setActivities(data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchActivity()
  }, [])

  useEffect(() => {
    if (!socket) return;
    const eventHandler = action => {
      if (activities.length === 500) {
        const dataCopy = [...activities];
        dataCopy.pop();
        dataCopy.unshift({
          action,
          date: new Date().toISOString()
        })
        setActivities(dataCopy)
      } else {
        setActivities(prevState => [
          {
            action,
            date: new Date().toISOString()
          },
          ...prevState
        ])
      }
    }
    socket.on('activity_log', eventHandler)

    return () => socket.off('activity_log', eventHandler)
  }, [activities, socket])

  return (
    <Container>
      {activities.map((activitiy, idx) => (
        <Fade key={idx} in>
          <Row>
            <Time>{moment(activitiy.date).format('HH:mm')}</Time>
            <Action>{activitiy.action}</Action>
          </Row>
        </Fade>
      ))}
      {activities.length === 0 && (
        <div className="text-center my-3">No recent activities</div>
      )}
    </Container>
  )
}

export default memo(RecentActivities)
