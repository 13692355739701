import { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'

import BranchTable from "../BranchTable";
import {
  HeaderContainer,
  HeaderTitle,
  SearchInputContainer,
  SearchInput,
  TableContainer
} from "./branchTableContent.components";

const BranchTableContent = ({ socket }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Branches</HeaderTitle>
        <SearchInputContainer isFocus={isFocus}>
          <AiOutlineSearch />
          <SearchInput
            placeholder="Search branch..."
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={e => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </SearchInputContainer>
      </HeaderContainer>
      <TableContainer>
        <BranchTable socket={socket} searchValue={searchValue} />
      </TableContainer>
    </>
  )
}

export default BranchTableContent;
