import { useState, useEffect } from 'react'
import moment from 'moment';
import { Row as RbRow, Col } from 'react-bootstrap'

import {
  Container,
  Row,
  Time,
  Action
} from './deviceLog.components'
import HeartbeatChart from '../HeartbeatChart';

const DeviceLog = ({ data, type, socket, clientId }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!socket) return
    const eventHandler = () => {
      setChartData(prevState => [...prevState, [new Date().getTime(), 3]])
    }
    socket.on(`log_${clientId}`, eventHandler)

    return () => socket && socket.off(`log_${clientId}`, eventHandler)
  }, [socket, clientId])

  useEffect(() => {
    const addZeroData = () => setChartData(prevState => [...prevState, [new Date().getTime(), 0]]);

    const interval = setInterval(addZeroData, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <RbRow>
      <Col xs={type === 'heartbeat' ? 6 : 12} className='p-0'>
        <Container>
          {data[type].map((val, idx) => (
            <Row key={idx}>

              <Time isDisconnect={val.action === 'disconnected'}>{moment(val.time).format(type === "heartbeat" ? 'HH:mm:ss' : "yyyy-MM-DD HH:mm:ss")}</Time>
              <Action isDisconnect={val.action === 'disconnected'}>{val.action}</Action>
            </Row>
          ))}
          {data[type].length === 0 && (
            <div className="text-center py-2">No recent {type} logs</div>
          )}
        </Container>
      </Col>
      {type === "heartbeat" && (
        <Col xs={6} className="p-0">
          <HeartbeatChart data={chartData} />
        </Col>
      )}
    </RbRow>
  )
}

export default DeviceLog
