import { useContext } from "react";
import { Button } from "react-bootstrap";
import { ThemeContext } from "styled-components";
import clsx from 'clsx';

import {
  Modal
} from './confirmModal.components'

const ConfirmModal = ({ onHide, onConfirm, title, text, ...props }) => {
  const theme = useContext(ThemeContext)

  return (
    <Modal {...props} onHide={onHide} className="test">
      <Modal.Header closeButton>
        <Modal.Title className={clsx({ 'text-white': theme.type === "dark" })}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={clsx({ 'text-white': theme.type === "dark" })}>
        {text}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide} >Cancel</Button>
        <Button onClick={onConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
