import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';

export const Content = styled.div`
  display: grid;
  place-items: center;
`;

export const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    .MuiPaper-root {
      background-color: ${p => p.theme.colors.surface};
    }
  }
`;

export const PingText = styled.span`
  color: ${p => p.theme.colors.text};
`;

export const PingStatus = styled.div`
  color: ${p => p.color};

  svg {
    font-size: 20;
    color: ${p => p.color};
  }
`;
