import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: hidden;
  padding-right: 1em;
  font-size: 0.9em;
  max-height: 436px;

  &:hover {
    overflow-y: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75em 0;
  border-bottom: 1px solid ${p => p.theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const Time = styled.div`
  margin-right: 1em;
  color: ${p => p.theme.colors.primary};
  font-weight: 500;
`;

export const Action = styled.div`
  color: ${p => p.theme.colors.text};
`;
