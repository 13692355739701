import { memo, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Line } from 'react-chartjs-2'

const VisitorChart = ({ overview }) => {
  const year = new Date().getFullYear();
  const theme = useContext(ThemeContext)

  const data = canvas => {
    const ctx = canvas.getContext("2d");
    const blueGradient = ctx.createLinearGradient(0, 0, 0, 400);
    blueGradient.addColorStop(0, theme.colors.chart);
    blueGradient.addColorStop(1, theme.colors.chart + '00');

    const yellowGradient = ctx.createLinearGradient(0, 0, 0, 400);
    yellowGradient.addColorStop(0, '#fffb00');
    yellowGradient.addColorStop(1, '#fffb0000');

    return {
      labels: Object.keys(overview.totalVisitor[year]),
      datasets: [
        {
          label: 'Total Visitor',
          fill: true,
          backgroundColor: blueGradient,
          borderColor: theme.colors.chart,
          tension: 0.4,
          data: Object.values(overview.totalVisitor[year])
        },
        {
          label: 'Unique Visitor',
          fill: true,
          backgroundColor: yellowGradient,
          borderColor: '#fffb00',
          tension: 0.4,
          data: Object.values(overview.uniqueVisitor[year])
        },
      ]
    }
  }

  const options = {
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: theme.colors.text
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          color: theme.colors.text
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: theme.colors.text
        }
      }
    }
  }

  return (
    <Line data={data} options={options} />
  )
}

export default memo(VisitorChart);
