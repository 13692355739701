import styled from 'styled-components';
import { lighten } from 'polished';
import { Card, Button, Alert as RbAlert } from 'react-bootstrap';
import { TextField } from '@material-ui/core';

export const LoginCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  width: 400px;
  border-radius: 10px;
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin: 20px 0 30px;
`;

export const Logo = styled.img``;

export const Alert = styled(RbAlert)`
  margin-bottom: 1.5em;
`;

export const TextFieldContainer = styled.div`
  margin-bottom: 1em;
`;

export const InputText = styled(TextField)`
  width: 100%;
`;

export const LoginButton = styled(Button)`
  background-color: ${p => p.theme.colors.primary};
  width: 100%;
  border: none;
  padding: 15px 0;

  &:hover {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)};
  }

  &:active,
  &:focus {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)} !important;
  }
`;
