import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import {
  LoginCard,
  LogoContainer,
  Logo,
  TextFieldContainer,
  InputText,
  LoginButton, Alert
} from './login.components';

import axios from '../../axios';
import logo from '../../assets/FITCO-Logo.png';


const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: '/' } };

  const handleOnSubmit = async e => {
    e.preventDefault();

    try {
      const { data } = await axios.post('/v1/auth/signin', { email, password });
      localStorage.setItem('token', `jwt ${data.token}`);
      history.replace(from)
    } catch (err) {
      setError(err.response.data.err)
    }
  }

  return (
    <Container>
      <LoginCard>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        {error && <Alert variant="danger" >{error}</Alert>}
        <form onSubmit={handleOnSubmit}>
          <TextFieldContainer>
            <InputText label="Email" variant="outlined" onChange={e => setEmail(e.target.value)} />
          </TextFieldContainer>
          <TextFieldContainer>
            <InputText label="Password" type="password" variant="outlined" onChange={e => setPassword(e.target.value)} />
          </TextFieldContainer>
          <LoginButton type="submit" >SIGN IN</LoginButton>
        </form>
      </LoginCard>
    </Container>
  );
};

export default Login;
