import styled from 'styled-components';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import { lighten } from 'polished';

export const ToggleButton = styled(MuiToggleButton)`
  && {
    padding: 4px 10px;
    border: 1px solid ${p => p.theme.colors.primary};
    color: #ccc;

    &:hover {
      background-color: ${p => p.theme.colors.primary}0a;
    }

    &.Mui-selected {
      background-color: ${p => p.theme.colors.primary};
      color: #fff;

      &:hover {
        background-color: ${p => lighten(0.05, p.theme.colors.primary)};
      }
    }
  }
`;
