import Sidebar from '../../components/Sidebar';
import BranchContent from '../../components/BranchContent';

const Branch = () => {
  return (
    <>
      <Sidebar />
      <BranchContent />
    </>
  )
}

export default Branch;
