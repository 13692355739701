import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${p => p.theme.sidebar.width}px;
  padding: 2em 0;
  background-color: ${p => p.theme.colors.primary};
`;

export const Logo = styled.img`
  width: 90%;
  padding-left: 0.5em;
  margin-bottom: 2em;
`;

export const SidebarNav = styled.ul`
  margin: 0;
  padding: 0;
`;

export const SidebarNavList = styled.li`
  list-style-type: none;
`;

export const SidebarNavIcon = styled.div`
  svg {
    font-size: 1.6em;
    padding-bottom: 2px;
  }
`;

const sidenavLinkStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #ffffff9c;
  padding: 1.5em 1.25em;
  cursor: pointer;

  &.active {
    background-color: ${p => lighten(0.07, p.theme.colors.primary)};
    ${SidebarNavIcon} {
      svg {
        color: #fff;
      }
    }
  }

  &.active::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    background-color: #fff;
  }

  &:hover {
    color: #fff;
  }
`;

export const SidebarNavLink = styled(Link)`
  ${sidenavLinkStyle}
`;

export const SidebarNavLinkAction = styled.div`
  ${sidenavLinkStyle}
`;
