import { FaUserFriends } from 'react-icons/fa'
import { AiFillClockCircle } from 'react-icons/ai'
import { Row } from 'react-bootstrap';

import VisitorChart from '../VisitorChart';
import OverviewCard from '../OverviewCard';
import ScrollableVisitorChart from '../ScrollableVisitorChart'
import { getVisitor } from '../../utils/getVisitor'
import { getVisitorGrowth } from '../../utils/getVisitorGrowth'
import { getAvgLongVisitGrowth } from '../../utils/getAvgLongVisitGrowth'

import {
  HeaderTitle,
  ContentContainer,
  Content,
  Title
} from './overview.components'

const Overview = ({ overview, title = "Overview" }) => {

  return (
    <>
      <HeaderTitle className="mb-3">{title}</HeaderTitle>
      <Row style={{ marginBottom: '1em', alignItems: 'stretch' }}>
        <ContentContainer xs={4}>
          <Content>
            <OverviewCard
              icon={<FaUserFriends />}
              title="Total Visitor"
              value={getVisitor(overview.totalVisitor)}
              color="#39c8ff"
              growth={getVisitorGrowth(overview.totalVisitor)}
            />
          </Content>
        </ContentContainer>
        <ContentContainer xs={4}>
          <Content>
            <OverviewCard
              icon={<FaUserFriends />}
              title="Total Unique Visitor"
              value={getVisitor(overview.uniqueVisitor)}
              color="#ff2292"
              growth={getVisitorGrowth(overview.uniqueVisitor)}
            />
          </Content>
        </ContentContainer>
        <ContentContainer xs={4}>
          <Content>
            <OverviewCard
              icon={<AiFillClockCircle />}
              title="Avg. Long Visit"
              value={`${overview.thisMonthAvgLongVisit} Mins`}
              color="#a645ff"
              growth={getAvgLongVisitGrowth(overview.thisMonthAvgLongVisit, overview.lastMonthAvgLongVisit)}
            />
          </Content>
        </ContentContainer>
      </Row>
      <Row style={{ marginBottom: '1em', alignItems: 'stretch' }}>
        <ContentContainer xs={6}>
          <Content className="d-flex flex-column justify-content-between">
            <Title>Visitor Overview</Title>
            <VisitorChart overview={overview} />
          </Content>
        </ContentContainer>
        <ContentContainer xs={6}>
          <Content className="d-flex flex-column justify-content-between">
            <Title>Visitor Hourly</Title>
            <ScrollableVisitorChart data={overview.visitorDataPoints} />
          </Content>
        </ContentContainer>
      </Row>
    </>
  )
}

export default Overview
