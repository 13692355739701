import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
`;

export const HeaderTitle = styled.h6`
  color: ${p => p.theme.colors.text};
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3em 0.5em;
  border: 1px solid ${p => (p.isFocus ? p.theme.colors.primary : '#ccc')};
  border-radius: 5px;

  svg {
    margin-right: 0.5em;
    color: ${p => (p.isFocus ? p.theme.colors.primary : '#ccc')};
  }
`;

export const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  font-size: 0.95em;
  color: ${p => p.theme.colors.text};
  caret-color: ${p => p.theme.colors.primary};

  &::placeholder {
    color: #ccc;
  }

  &:focus-visible {
    && {
      outline: none;
    }
  }
`;

export const TableContainer = styled.div`
  max-height: 420px;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }
`;
