import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap'
import CircularProgress from '@material-ui/core/CircularProgress'

import axios from '../../axios';
import BranchTableContent from '../BranchTableContent';
import {
  Container,
  ContentContainer,
  Content,
} from './dashboardContent.components'
import RecentActivities from '../RecentActivities';
import Overview from '../Overview';
import { useSocket } from '../../hooks/useSocket'


const DashboardContent = () => {
  const [overview, setOverview] = useState([]);
  const socket = useSocket();

  useEffect(() => {
    let isMounted = true;
    fetchOverview(isMounted);

    return () => isMounted = false;
  }, [])

  useEffect(() => {
    if (!socket) return;

    const eventHandler = () => {
      fetchOverview();
    }

    socket.on('member_got_in', eventHandler)

    return () => socket && socket.off('member_got_in', eventHandler)
  }, [socket])

  const fetchOverview = async (isMounted) => {
    try {
      const { data } = await axios.get('/v1/iot/get/overview', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      isMounted && setOverview(data);
    } catch (err) {
      console.error(err)
    }
  }



  if (overview.length === 0) return <Container className="text-center py-4"><CircularProgress /></Container>
  return (
    <Container>
      <Overview overview={overview} title="Dashboard" />
      <Row className="align-items-start">
        <ContentContainer xs={8}>
          <Content>
            <BranchTableContent socket={socket} />
          </Content>
        </ContentContainer>
        <ContentContainer xs={4} className="relative">
          <Content className="pr-0">
            <h6>Recent Activities</h6>
            <RecentActivities socket={socket} />
          </Content>
        </ContentContainer>
      </Row>
    </Container>
  )
}

export default DashboardContent
