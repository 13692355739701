import { createGlobalStyle, css } from 'styled-components';

const darkModeStyles = css`
  #apexchartshourlyvisitor .apexcharts-tooltip {
    color: #000000;
  }

  .apexcharts-zoomin-icon:hover svg,
  .apexcharts-zoomout-icon:hover svg,
  .apexcharts-zoom-icon:hover svg,
  .apexcharts-pan-icon:hover svg,
  .apexcharts-reset-icon:hover svg,
  .apexcharts-menu-icon:hover svg {
    fill: #fff !important;
  }
`;

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif !important;
    background-color: #f5f5f5 !important;
  }

  ${p => p.theme.type === 'dark' && darkModeStyles}
`;
