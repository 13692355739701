import {
  ToggleButton
} from './toggleButton.components'

const ToggleButtonComponent = ({ children, ...props }) => {
  return (
    <ToggleButton {...props}>
      {children}
    </ToggleButton>
  )
}

export default ToggleButtonComponent