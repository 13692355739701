import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

export const HeaderTitle = styled.h2`
  color: ${p => p.theme.colors.text};
`;

const absolute = css`
  position: absolute;
  top: 0;
  left: 0.5em;
  right: 0.5em;
  bottom: 0;
`;

export const ContentContainer = styled(Col)`
  padding: 0 0.5em;
`;

export const Content = styled.div`
  padding: 1em;
  height: 100%;
  background-color: ${p => p.theme.colors.surface};
  color: ${p => p.theme.colors.text};
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);

  ${p => p.absolute && absolute}
`;

export const Title = styled.h6`
  margin-bottom: 1em;
`;
