import styled from 'styled-components';
import { Modal as RbModal } from 'react-bootstrap';

export const Modal = styled(RbModal)`
  .modal-dialog {
    .modal-content {
      background-color: ${p => p.theme.colors.surface};

      .modal-header {
        border: none;

        .close {
          color: #fff;
        }
      }

      .modal-footer {
        border: none;
      }
    }
  }
`;
