import { getMonthName } from './getMonthName';

export const getVisitorGrowth = obj => {
  const now = new Date();
  const thisMonth = now.getMonth();

  const thisMonthVisitor = obj[now.getFullYear()][getMonthName(thisMonth)];
  const lastMonthVisitor =
    thisMonth === 0
      ? obj[now.getFullYear() - 1]['Dec']
      : obj[now.getFullYear()][getMonthName(thisMonth - 1)];

  if (lastMonthVisitor === thisMonthVisitor) return 0;
  if (lastMonthVisitor === 0) return 100.0;

  const result =
    ((thisMonthVisitor - lastMonthVisitor) / lastMonthVisitor) * 100;

  return Number(result.toFixed(1));
};
