import { useState, useEffect, useCallback, useRef } from 'react'
import { useSnackbar } from 'notistack'
import { VscDebugRestart } from 'react-icons/vsc'
import { FaWifi } from 'react-icons/fa'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Container,
  Content,
  StatusActionContainer,
  ActionContainer,
  Badge,
  LogsContainer,
  ActionButton
} from './deviceCard.components'
import Logs from '../Logs'
import axios from '../../axios';
import ConfirmModal from '../ConfirmModal';

const DeviceCard = ({ device, socket, isPingModalOpen }) => {
  const { clientId, status } = device;

  const [heartbeats, setHeartbeats] = useState([]);
  const [connections, setConnections] = useState([]);
  const [isConnected, setIsConnected] = useState(status);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const pingTimeout = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const fetchHeartbeat = useCallback(async () => {
    try {
      const { data } = await axios.get(`/v1/iot/get/logs/${clientId}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })

      setHeartbeats(data.heartbeat);
      setConnections(data.connection);
    } catch (err) {
      console.error(err);
    }
  }, [clientId]);

  useEffect(() => {
    fetchHeartbeat()
  }, [fetchHeartbeat])

  useEffect(() => {
    const eventHandler = d => {
      setHeartbeats(prevState => [
        { time: new Date(d).toISOString(), action: 'Heartbeat', clientId },
        ...prevState,
      ]);
      if (!isConnected) setIsConnected(true)
    }
    socket.on(`log_${clientId}`, eventHandler);

    return () => socket.off(`log_${clientId}`, eventHandler);
  }, [clientId, socket, isConnected])

  useEffect(() => {
    if (!socket) return
    const eventHandler = d => {
      if (isPingModalOpen) return
      if (pingTimeout.current) clearTimeout(pingTimeout.current)
      enqueueSnackbar(`${clientId} replied ${d}`, {
        variant: 'success'
      })
    }

    socket.on(`response_${clientId}`, eventHandler);

    return () => socket && socket.off(`response_${clientId}`, eventHandler);
  }, [clientId, socket, enqueueSnackbar, isPingModalOpen])

  useEffect(() => {
    if (!socket) return
    const eventHandler = d => {
      setIsConnected(d);
      setConnections(prevState => [
        {
          time: new Date().toISOString(),
          action: d === 0 ? 'disconnected' : 'connected',
          clientId
        },
        ...prevState,
      ]);
    }

    socket.on(`status_${clientId}`, eventHandler);

    return () => socket && socket.off(`status_${clientId}`, eventHandler);
  }, [clientId, socket]);

  const onPingClick = () => {
    enqueueSnackbar(`Pinging to ${clientId}...`, {
      variant: 'info'
    })
    setTimeout(() => socket.emit('ping_device', clientId), 1000)
    pingTimeout.current = setTimeout(() => {
      enqueueSnackbar(`${clientId} request timed out`, {
        variant: 'error'
      })
    }, 5000)
  }

  const onRestartDeviceClick = () => {
    socket.emit('restart_device', clientId);
    setIsConfirmOpen(false);
  }

  return (
    <Container>
      <Content>
        <h6 className="mb-3">{clientId}</h6>
        <StatusActionContainer>
          <Badge isConnected={isConnected} >
            {isConnected ? 'Connected' : 'Disconnected'}
          </Badge>
          <ActionContainer>
            <Tooltip arrow title="Ping device">
              <ActionButton onClick={onPingClick} className='ml-1 text-white'>
                <FaWifi />
              </ActionButton>
            </Tooltip>
            <Tooltip arrow title="Restart device">
              <ActionButton onClick={() => setIsConfirmOpen(true)} className='ml-1'>
                <VscDebugRestart />
              </ActionButton>
            </Tooltip>
          </ActionContainer>
        </StatusActionContainer>
        <LogsContainer className="mt-2">
          <Logs data={{ heartbeat: heartbeats, connection: connections }} socket={socket} clientId={clientId} />
        </LogsContainer>
      </Content>
      <ConfirmModal
        show={isConfirmOpen}
        title="Restart Device"
        text={`Are you sure want to restart ${clientId}?`}
        onHide={() => setIsConfirmOpen(false)}
        onConfirm={onRestartDeviceClick}
      />
    </Container>
  )
}

export default DeviceCard