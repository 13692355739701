import React from 'react';
import ReactDOM from 'react-dom';
import Fade from '@material-ui/core/Fade';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from './context/theme';

import App from './App';
import { SnackbarProvider } from 'notistack';
import 'bootstrap/dist/css/bootstrap.min.css';

import muiTheme from './themes/muiTheme';

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        TransitionComponent={Fade}
      >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
