import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import clsx from 'clsx';

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Dialog,
  Content,
  PingText,
  PingStatus
} from './pingModal.components'

const PingModal = ({ devices, handleClose, open, socket, groupId, branchName, ...props }) => {
  const [pingCount, setPingCount] = useState(0)
  const [responseCount, setResponseCount] = useState(0);
  const onGetResponse = useCallback(() => setResponseCount(prevState => prevState + 1), []);
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (open) setResponseCount(0)
  }, [open])


  if (!devices) return null;


  const onPingAgain = () => {
    setResponseCount(0)
    setPingCount(prevState => prevState + 1)
    setTimeout(() => socket.emit(`ping_all_device`, groupId), 1000)
  }

  const onClose = () => {
    setResponseCount(0)
    handleClose();
    setPingCount(0);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...props}
      keepMounted={false}
    >
      <DialogTitle className={clsx({
        'text-white': theme.type === "dark"
      })}>{branchName ? `Pinging all devices in ${branchName}` : 'Pinging all devices'}</DialogTitle>
      <DialogContent>
        <Content>
          <div>
            {devices.map((device, idx) => <DevicePingStatus key={idx} device={device} socket={socket} pingCount={pingCount} onGetResponse={onGetResponse} />)}
          </div>
        </Content>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={onPingAgain} color="primary" disabled={responseCount !== devices.length}>
          Ping again
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DevicePingStatus = ({ device, socket, pingCount, onGetResponse }) => {
  const [isResponded, setIsResponded] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const pingTimeout = useRef(null);
  const timoutValue = 5000;
  const theme = useContext(ThemeContext);

  useEffect(() => {
    let isMounted = true;

    if (pingCount !== 0) {
      if (isMounted) {
        setIsResponded(false);
        setIsTimeout(false);
      }
      pingTimeout.current = setTimeout(() => {
        if (isMounted) {
          setIsTimeout(true)
          onGetResponse()
        }
      }, timoutValue)
    }

    return () => isMounted = false;
  }, [pingCount, onGetResponse])

  useEffect(() => {
    if (!socket) return;

    let isMounted = true;

    const eventHandler = () => {
      if (pingTimeout.current) clearTimeout(pingTimeout.current);
      if (isMounted) {
        setIsResponded(true)
        onGetResponse();
      }
    }

    socket.on(`response_${device.clientId}`, eventHandler)

    return () => {
      socket && socket.off(`response_${device.clientId}`, eventHandler)
      isMounted = false
    }
  }, [device.clientId, socket, onGetResponse])

  useEffect(() => {
    let isMounted = true;

    if (!pingTimeout.current) {
      pingTimeout.current = setTimeout(() => {
        if (isMounted) {
          setIsTimeout(true)
          onGetResponse()
        }
      }, timoutValue)
    }

    return () => {
      isMounted = false
    };
  }, [device.clientId, onGetResponse])

  let content;

  if (!isResponded && !isTimeout) {
    content = (
      <>
        <CircularProgress size={20} className="mr-3" /> <PingText>Pinging to {device.clientId}...</PingText>
      </>
    )
  } else if (isResponded && !isTimeout) {
    content = (
      <PingStatus color={theme.colors.success} >
        <FaCheck className="mr-3" /> {device.clientId} replied OK
      </PingStatus>
    )
  } else {
    content = (
      <PingStatus color={theme.colors.error} >
        <FaTimes className="mr-3" /> {device.clientId} request timed out
      </PingStatus>
    )
  }

  return (
    <div onClick={() => setIsResponded(true)} className="d-flex align-items-center py-3">
      {content}
    </div>
  )
}

export default PingModal
