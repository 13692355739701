import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  background-color: ${p => p.color || '#ccc'};
  padding: 1em;
  border-radius: 10px;
  transform: scale(0.9);
  margin-right: 1em;

  svg {
    font-size: 1.8em;
    color: #fff;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 0.8em;
  color: #ccc;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Value = styled.div`
  font-size: 1.8em;
  font-weight: 500;
  line-height: 35px;
`;

export const Growth = styled.div`
  margin-left: 0.5em;
  font-weight: 500;
  color: ${p => (p.isPositive ? p.theme.colors.primary : p.theme.colors.error)};
`;
