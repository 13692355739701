import Chart from 'react-apexcharts'

const HeartbeatChart = ({ data }) => {
  const options = {
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      animations: {
        easing: "linear",
        dynamicAnimation: {
          speed: 500
        }
      },
    },
    xaxis: {
      type: "datetime",
      range: 1000 * 60,
      labels: {
        show: false
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: -0.5,
      max: 3.5,
      labels: {
        show: false
      }
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false
    }
  }

  const series = [
    {
      name: 'heartbeat',
      data
    }
  ]

  return <Chart
    options={options}
    series={series}
    height="100%"
    type="area"
  />
}

export default HeartbeatChart
