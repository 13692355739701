import Sidebar from '../../components/Sidebar';
import DashboardContent from '../../components/DashboardContent';

const Dashboard = () => {
  return (
    <>
      <Sidebar />
      <DashboardContent />
    </>
  )
}

export default Dashboard;
