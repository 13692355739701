import { useState, useContext } from 'react';
import { AiFillHome, AiOutlineLogout } from 'react-icons/ai'
import { BsSun, BsMoon } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import { ThemeContext as StyledThemeContext } from 'styled-components'
import { ThemeContext } from '../../context/theme';
import { Tooltip } from '@material-ui/core';

import ConfirmModal from '../ConfirmModal';
import {
  Container,
  Logo,
  SidebarNav,
  SidebarNavList,
  SidebarNavLink,
  SidebarNavIcon,
  SidebarNavLinkAction
} from './sidebar.components';

import logo from '../../assets/FITCO-Logo.png';
import { useAuth } from '../../hooks/useAuth';

const Sidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const { logout } = useAuth();
  const theme = useContext(StyledThemeContext);
  const { setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    if (theme.type === 'light') {
      setTheme("dark");
      localStorage.setItem('theme', 'dark')
    } else {
      setTheme("light");
      localStorage.setItem('theme', 'light')
    }
  }

  const onLogout = () => {
    logout();
    history.push('/login')
  }

  return (
    <>
      <Container>
        <Logo src={logo} />
        <SidebarNav>
          <SidebarNavList>
            <SidebarNavLink to='/dashboard' className={['/', '/dashboard'].includes(pathname) ? 'active' : null}>
              <SidebarNavIcon>
                <AiFillHome />
              </SidebarNavIcon>
            </SidebarNavLink>
            <Tooltip title="Toggle light/dark theme" arrow>
              <SidebarNavLinkAction onClick={toggleTheme}>
                <SidebarNavIcon>
                  {theme.type === "light" ? <BsSun /> : <BsMoon />}
                </SidebarNavIcon>
              </SidebarNavLinkAction>
            </Tooltip>
            <Tooltip title="Logout" arrow>
              <SidebarNavLinkAction onClick={() => setIsModalOpen(true)}>
                <SidebarNavIcon>
                  <AiOutlineLogout />
                </SidebarNavIcon>
              </SidebarNavLinkAction>
            </Tooltip>
          </SidebarNavList>
        </SidebarNav>
      </Container>
      <ConfirmModal
        title="Logout"
        text="Are you sure want to logout?"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onConfirm={onLogout}
      />
    </>
  )
}

export default Sidebar;
