import { useState, useEffect, memo, useContext } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { FaWifi } from 'react-icons/fa'
import Tooltip from '@material-ui/core/Tooltip';
import { ThemeContext } from 'styled-components'
import PingModal from '../PingModal';
import axios from '../../axios';
import { ActionButton, Table, ActionButtonLink } from './branchTable.components'

const BranchTable = ({ socket, searchValue }) => {
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isPingModalOpen, setIsPingModalOpen] = useState(false)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    fetchBranch()
  }, [])

  useEffect(() => {
    if (!socket) return
    const eventHandler = () => {
      fetchBranch()
    }
    socket.on('device_status', eventHandler)

    return () => socket.off('device_status', eventHandler)
  }, [socket])

  const fetchBranch = async () => {
    try {
      const { data } = await axios.get('/v1/iot/get/group', {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      });
      setBranches(data.data);
    } catch (err) {
      console.error(err)
    }
  }

  const onPingClick = branch => {
    setSelectedBranch(branch);
    setIsPingModalOpen(true);
    setTimeout(() => socket.emit(`ping_all_device`, branch._id), 1000)
  }

  const actionColumnWidth = 120;
  return (
    <>
      <Table className="mb-0" variant={theme.type === "dark" ? "dark" : null}>
        <thead>
          <tr>
            <th>#</th>
            <th>Branch</th>
            <th>Devices</th>
            <th style={{ width: actionColumnWidth }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {branches.filter(b => b.name.toLowerCase().match(searchValue.toLowerCase())).map((branch, idx) => (
            <tr key={branch._id} style={{
              color: branch.devices.filter(d => d.status === 1).length ? theme.colors.text : theme.colors.error
            }}>
              <td><strong>{idx + 1}</strong></td>
              <td><strong>{branch.name}</strong></td>
              <td>{branch.devices.filter(d => d.status === 1).length
                ? `${branch.devices.filter(d => d.status === 1).length} Device(s) Connected`
                : `No Device Connected`}</td>
              <td style={{ width: actionColumnWidth, display: 'flex', justifyContent: 'space-evenly' }}>
                <Tooltip arrow title="Ping devices" disableHoverListener={!Boolean(branch.devices.filter(d => d.status === 1).length)}>
                  <span>
                    <ActionButton disabled={!Boolean(branch.devices.filter(d => d.status === 1).length)} onClick={() => onPingClick(branch)}>
                      <FaWifi />
                    </ActionButton>
                  </span>
                </Tooltip>
                <Tooltip arrow title="View overview">
                  <ActionButtonLink to={`/branch/${branch._id}`}>
                    <ActionButton>
                      <AiFillEye />
                    </ActionButton>
                  </ActionButtonLink>
                </Tooltip>
              </td>
            </tr>
          ))}


          {branches.filter(b => b.name.toLowerCase().match(searchValue.toLowerCase())).length === 0 && (
            <tr className="text-center">
              <td colSpan="4" >No match result for "{searchValue}"</td>
            </tr>
          )}
        </tbody>
      </Table>
      <PingModal
        open={isPingModalOpen}
        handleClose={() => setIsPingModalOpen(false)}
        devices={selectedBranch?.devices}
        branchName={selectedBranch?.name}
        groupId={selectedBranch?._id}
        socket={socket}
        maxWidth="xs"
        fullWidth
      />
    </>
  )
}

export default memo(BranchTable)
