import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.div`
  padding: 0.5em 0;
  height: 100%;
`;

export const Content = styled.div`
  padding: 1em;
  background-color: ${p => p.theme.colors.surface};
  color: ${p => p.theme.colors.text};
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
`;

export const StatusActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionContainer = styled.div`
  display: flex;
`;

export const Badge = styled.div`
  background-color: ${p =>
    p.isConnected ? p.theme.colors.success : p.theme.colors.error};
  padding: 10px 20px;
  border-radius: 0.25em;
  color: #fff;
`;

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.colors.primary};
  border: none;
  padding: 0.7em 0.8em;

  &:hover {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)};
  }

  &:disabled {
    background-color: ${p => lighten(0.07, p.theme.colors.primary)};
  }

  &:active,
  &:focus {
    background-color: ${p => lighten(0.05, p.theme.colors.primary)} !important;
  }
`;

export const LogsContainer = styled.div`
  padding: 1em;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 10px;
`;
