import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const AuthRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={
        () => isAuthenticated
          ? <Redirect to='/' />
          : children
      }
    />
  )
}

export default AuthRoute
