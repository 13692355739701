export const lightTheme = {
  type: 'light',
  colors: {
    primary: '#26a0fc',
    secondary: '#daefff',
    success: '#02cc02',
    error: '#ff5656',
    surface: '#fff',
    background: '#f5f5f5',
    text: '#000',
    border: '#26a0fc26',
    chart: '#26a0fc',
  },
  sidebar: {
    width: 80,
  },
};

export const darkTheme = {
  type: 'dark',
  colors: {
    primary: '#26a0fc',
    secondary: '#daefff',
    success: '#34ff00',
    error: '#ff2d2d',
    surface: '#2f2f2f',
    background: '#000',
    text: '#fff',
    border: '#cccccc26',
    chart: '#0781dd',
  },
  sidebar: {
    width: 80,
  },
};
