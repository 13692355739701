import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useSocket } from '../../hooks/useSocket';
import axios from '../../axios';
import DeviceCard from '../DeviceCard';
import PingModal from '../PingModal';
import Overview from '../Overview'
import {
  Container,
  HeaderContainer,
  HeaderTitle,
  PingButton,
} from './branchContent.components'

const BranchContent = () => {
  const [devices, setDevices] = useState(null);
  const [overview, setOverview] = useState([]);
  const [isPingModalOpen, setIsPingModalOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const socket = useSocket();
  const { groupId } = useParams();

  const fetchBranchOverview = useCallback(async (isMounted) => {
    try {
      const { data } = await axios.get(`/v1/iot/get/overview/${groupId}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      isMounted && setOverview(data)
    } catch (err) {
      if (err.response.status === 404) isMounted && setIsNotFound(true);
      console.error(err);
    }
  }, [groupId]);

  const fetchDevices = useCallback(async (isMounted) => {
    try {
      const { data } = await axios.get(`/v1/iot/get/devices/${groupId}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      isMounted && setDevices(data.data)
    } catch (err) {
      if (err.response.status === 404) isMounted && setIsNotFound(true);
      console.error(err);
    }
  }, [groupId]);

  useEffect(() => {
    let isMounted = true;
    fetchBranchOverview(isMounted);
    fetchDevices(isMounted);

    return () => isMounted = false;
  }, [fetchDevices, fetchBranchOverview]);

  useEffect(() => {
    if (!socket) return;

    const eventHandler = () => {
      fetchBranchOverview();
    }

    socket.on('member_got_in', eventHandler)

    return () => socket && socket.off('member_got_in', eventHandler)
  }, [socket, fetchBranchOverview])

  const onPingAllDevicesClick = () => {
    setIsPingModalOpen(true);
    setTimeout(() => socket.emit('ping_all_device', groupId), 1000);
  }

  if (isNotFound) return <Container><h1>404 not found</h1></Container>
  if (!socket || overview.length === 0) return <Container className="text-center py-4"><CircularProgress /></Container>

  return (
    <Container>
      <Overview overview={overview} />
      <HeaderContainer>
        <HeaderTitle>Devices</HeaderTitle>
        <PingButton onClick={onPingAllDevicesClick} >Ping all devices</PingButton>
      </HeaderContainer>
      <Row>
        {devices && devices.map(device =>
          <Col key={device._id} xs={4} style={{ padding: '0 .5em' }}>
            <DeviceCard
              socket={socket}
              device={device}
              isPingModalOpen={isPingModalOpen}
            />
          </Col>)}
      </Row>
      <PingModal
        handleClose={() => setIsPingModalOpen(false)}
        open={isPingModalOpen}
        devices={devices}
        groupId={groupId}
        socket={socket}
        maxWidth="xs"
        fullWidth
      />
    </Container>
  )
}

export default BranchContent
