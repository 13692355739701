import { createMuiTheme } from '@material-ui/core';
import { lightTheme } from './theme';

export default createMuiTheme({
  palette: {
    primary: {
      main: lightTheme.colors.primary,
    },
    secondary: {
      main: lightTheme.colors.secondary,
    },
  },
});
