import { BrowserRouter as Router, Switch } from 'react-router-dom';

import AuthRoute from './routes/AuthRoute';
import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Branch from './pages/Branch';
import { GlobalStyles } from './themes/global';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Switch>
          <AuthRoute exact path="/login">
            <Login />
          </AuthRoute>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/branch/:groupId">
            <Branch />
          </PrivateRoute>
        </Switch>
      </Router>
    </>
  );
};

export default App;
